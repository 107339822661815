<template>
  <main
    class="flex bg-white md:bg-transparent rounded-md pt-4 border border-dark-100 md:p-0 md:border-0 flex-col md:flex-row items-start"
  >
    <div
      class="flex mx-4 md:mx-0 flex-row flex-wrap md:flex-col w-full md:w-52 md:items-start"
    >
      <button
        v-for="(btn, i) in pages"
        :key="i"
        @click="switchView(i)"
        :class="
          btn.active
            ? 'bg-white border-outlineGray md:rounded-l-xl md:shadow-lg text-secondary font-semibold'
            : 'text-dark-800 border-dark-100 md:border-0'
        "
        class="capitalize text-sm px-4 py-3 focus:outline-none text-center md:text-left whitespace-nowrap w-fit border md:w-full"
      >
        {{ btn.title }}
      </button>
    </div>
    <div
      class="relative w-full text-sm h-auto lg:w-8/12 bg-white md:rounded-tr-none md:rounded-tr-xl md:rounded-tl-none md:rounded-xl md:border md:border-dark-50 md:shadow-md"
    >
      <!-- <span @click="toggleView" class="absolute top-4 right-4 cursor-pointer">
        <img class="" src="@/assets/icons/close.svg" alt="" />
      </span> -->
      <span></span>
      <keep-alive>
        <component :is="display" />
      </keep-alive>
    </div>
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, onUnmounted, shallowRef } from "vue";
import { useRoute } from "vue-router";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";

//// View Components ////
import EducationHistoryView from "@/components/Employee/View/Education/EducationHistory.vue";
import CertificationView from "@/components/Employee/View/Education/Certification.vue";
import AwardView from "@/components/Employee/View/Education/Award.vue";

//// Edit Components ////
import EducationHistoryEdit from "@/components/Employee/Edit/Education/EducationHistory.vue";
import CertificationEdit from "@/components/Employee/Edit/Education/Certification.vue";
import AwardEdit from "@/components/Employee/Edit/Education/Award.vue";

const store = useDataStore();
const { pageIndex } = storeToRefs(store);

const display = shallowRef(EducationHistoryEdit);
const currentPage = ref({});

const pages = reactive([
  {
    title: "Educational History",
    component: EducationHistoryEdit,
    viewComponent: EducationHistoryView,
    active: true,
    live: "view",
  },
  {
    title: "Certification",
    component: CertificationEdit,
    viewComponent: CertificationView,
    active: false,
    live: "view",
  },
  {
    title: "Award",
    component: AwardEdit,
    viewComponent: AwardView,
    active: false,
    live: "view",
  },
]);

onMounted(() => {
  switchView(pageIndex.value);
});

onUnmounted(() => {
  store.$patch({
    pageIndex: 0,
  });
});
const toggleView = () => {
  store.$patch({
    pageIndex: currentPage,
  });
  window.location.reload();
};

const switchView = (index) => {
  currentPage.value = index;

  display.value = pages[index].component;
  pages.forEach((el) => {
    el.active = false;
  });
  pages[index].active = true;
};
</script>

<style></style>
